// comment to trigger build
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

import {
  Accordion,
  Columns,
  Hero,
  LeshenPhoneCTA,
  Link,
  PackageCard,
  SplitContent,
  Stack,
  useMapi,
  VariableContent,
  ItemBank,
} from '@leshen/gatsby-theme-leshen'

import { Typography, List, ListItem, Dropdown } from '@leshen/ui'
import { graphql, navigate } from 'gatsby'

import { stateFaq } from '../data/faq'

import BroadbandNutritionLabels from '../components/BroadbandNutritionLabels'

const StateTemplate = ({ data }) => {
  const { State, Meta_Description, Page_Path, Meta_Title, Promo_Code } =
    data.pageInfo.edges[0].node

  const { rotatedNumber } = useMapi()

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: `/availability${Page_Path}`,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data?.allContentfulHeader.edges[0].node,
      footer: data?.allContentfulFooter.edges[0].node,
    },
  }

  const packages = data?.allBrandyPackage?.edges?.map((current) => ({
    node: current?.node,
  }))

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: `/availability${current.node.Page_Path.toLowerCase()}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Hero
            textColor="Light"
            mobileAlignImageToBottom
            className="center-mobile-image"
            image={
              <StaticImage
                src="../images/hero/hero-desktop.jpg"
                alt="An arial view of the state's landscape"
                placeholder="blurred"
              />
            }
            mobileImage={
              <StaticImage
                src="../images/hero/hero-mobile.png"
                alt="An arial view of the states landscape"
                placeholder="blurred"
              />
            }
          >
            <Typography variant="h1">
              Hughesnet Availability in {State}
            </Typography>
            <Typography>
              Your wait for high-speed internet is over. Hughesnet is available
              in rural areas across the US, so you can access fast speeds even
              in remote regions.
            </Typography>
            <LeshenPhoneCTA variant="hero" color="primary" leftIcon={false}>
              Order at
            </LeshenPhoneCTA>
          </Hero>
          <SplitContent
            hideImageOnMobile
            image={
              <StaticImage
                src="../images/split-content/woman-park-bench.jpg"
                alt="A woman using her tablet on a park bench"
                placeholder="blurred"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">Benefits of Hughesnet</Typography>
                <Typography variant="">
                  Hughesnet has been the leading high-speed satellite internet
                  provider in rural America for over 20 years—see what keeps
                  customers coming back.
                </Typography>
                <List type="unordered">
                  <ListItem>
                    <strong>Speed</strong>: New Hughesnet plans are faster than
                    ever, with download speeds up to 100 Mbps
                  </ListItem>
                  <ListItem>
                    <strong>Price</strong>: With a price-lock guarantee for all
                    plans, your monthly payment will not change for two years
                  </ListItem>
                  <ListItem>
                    <strong>Availability</strong>: Hughesnet satellite internet
                    is offered even in places where cable and fiber are not
                  </ListItem>
                  <ListItem>
                    <strong>Technology</strong>: With a Wi-Fi Modem featuring
                    Wi-Fi 6 technology, Hughesnet provides tech that you can
                    depend on
                  </ListItem>
                </List>
              </>
            }
          />

          <SplitContent
            className="reverse"
            hideImageOnMobile
            image={
              <StaticImage
                src="../images/split-content/map.png"
                alt="A map showing availability in the U.S."
                placeholder="blurred"
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Hughesnet: Statewide Availability
                </Typography>
                <Typography variant="">
                  Hughesnet provides high-speed satellite internet across the
                  US, so you can do the things you love online, no matter where
                  you live.
                </Typography>
                <List type="unordered">
                  <ListItem>
                    Satellite internet offered across the contiguous US and
                    Alaska
                  </ListItem>
                  <ListItem>
                    Strong connectivity in every room with Whole Home Wi-Fi
                  </ListItem>
                  <ListItem>Surf, stream, and game with less lag</ListItem>
                  <ListItem>Unlimited data</ListItem>
                  <ListItem>Download speeds up to 100 Mbps</ListItem>
                  <ListItem>
                    Free{' '}
                    <Link to="http://hughesnetplans.com/installation">
                      installation
                    </Link>{' '}
                    wherever you are
                  </ListItem>
                </List>
                <br />
                <Dropdown
                  className="dropdown primary-colors"
                  style={{ margin: 'auto' }}
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Select City"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <Typography variant="h2">Frequently asked questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion items={stateFaq({ State, rotatedNumber })} />
            </Stack>
          </VariableContent>

          <BroadbandNutritionLabels />

          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Hughesnet Internet Availability in {State} Cities
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            breadcrumbProps={null}
            customLink={null}
            style={{ paddingTop: '0px' }}
          >
            <ItemBank className="city-link-bank">
              {cityDropdownData?.map((current, i) => (
                <Link to={current.value} key={i}>
                  {current.label}
                </Link>
              ))}
            </ItemBank>
          </VariableContent>
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String, $stateAbbreviation: String) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerHughesnetAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          City
          Page_Path
          Promo_Code
          Meta_Title
          Meta_Description
        }
      }
    }
    allBrandyPackage(
      filter: { brandy_id: { in: ["hn-sigil-elite", "hn-sigil-select"] } }
    ) {
      edges {
        node {
          __typename
          id
          brandy_id
          brand_token
          details
          labelOffer
          name
          price {
            amount
            before
            currency
            sale
            prefix
            duration
            suffix
            disclaimer {
              text
              symbol
            }
          }
          bullets {
            text
            disclaimer {
              text
              symbol
            }
          }
        }
      }
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-termrequired" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-professionalinstallation" }
    ) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-footer2" }
    ) {
      id
      text
      symbol
    }
    disclaimerFour: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-footer3" }
    ) {
      id
      text
      symbol
    }
    disclaimerFive: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-hn-save25promo" }
    ) {
      id
      text
      symbol
    }
    disclaimerSix: brandyDisclaimer(brandy_id: { eq: "hn-copyright" }) {
      id
      text
      symbol
    }
    cities: allDatasetManagerHughesnetAoa(
      filter: {
        State_Abbreviation: { eq: $stateAbbreviation }
        Area_Type: { eq: "city" }
      }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    allContentfulHeader(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          id
          identifier
          __typename
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(filter: { identifier: { eq: "SEO - English" } }) {
      edges {
        node {
          id
          identifier
          __typename
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
