import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Column,
  Columns,
  Stack,
  Typography,
  VariableContent,
  LinkButton,
} from '@leshen/gatsby-theme-leshen'

import fusionPdf from '../../static/broadband-fusion-facts.pdf'
import elitePdf from '../../static/broadband-elite-facts.pdf'
import selectPdf from '../../static/broadband-select-facts.pdf'

const BroadbandNutritionLabels = () => (
  <VariableContent
    backgroundColor=""
    alignMainContent="center"
    mainContent={
      <>
        <Typography variant="h2">Broadband Labels</Typography>
        <Typography variant="body">
          As of April 10th, 2024, the FCC requires all internet service
          providers to provide broadband labels for home internet service plans.
          The broadband labels are intended to help consumers find the best plan
          for their needs. We value transparency and are happy to provide
          details regarding our service plans&lsquo; data, speeds, and fees.
          Please note that service plan availability is geographically
          dependent. Not all service plans are available to all consumers.
        </Typography>
      </>
    }
  >
    <Stack spacing="xxl">
      <Columns fullWidthImage>
        <Column
          className="center-children"
          image={
            <StaticImage
              style={{ marginBottom: '16px' }}
              src="../images/nutrition-labels/broadband-select-nutrition-label.png"
              alt="Hughesnet Broadband Select Facts"
              placeholder="blurred"
            />
          }
        >
          <LinkButton to={selectPdf} outlined>
            Download pdf of the Select Plan label
          </LinkButton>
        </Column>
        <Column
          className="center-children"
          image={
            <StaticImage
              style={{ marginBottom: '16px' }}
              src="../images/nutrition-labels/broadband-elite-nutrition-label.png"
              alt="Hughesnet Broadband Elite Facts"
              placeholder="blurred"
            />
          }
        >
          <LinkButton to={elitePdf} outlined>
            Download pdf of the Elite Plan label
          </LinkButton>
        </Column>
        <Column
          className="center-children"
          image={
            <StaticImage
              style={{ marginBottom: '16px' }}
              src="../images/nutrition-labels/broadband-fusion-nutrition-label.png"
              alt="Hughesnet Broadband Fusion Facts"
              placeholder="blurred"
            />
          }
        >
          <LinkButton to={fusionPdf} outlined>
            Download pdf of the Fusion Plan label
          </LinkButton>
        </Column>
      </Columns>
    </Stack>
  </VariableContent>
)

export default BroadbandNutritionLabels
