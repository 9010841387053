import React from 'react'
import { Typography, Link } from '@leshen/gatsby-theme-leshen'

export const cityFaq = () => {
  return [
    {
      title: `Will Hughesnet work in my area?`,
      content: (
        <div>
          <Typography>
            Yes, definitely! As long as your home has a clear view of the
            southern sky, you can access our{' '}
            <Link to="/internet">satellite internet plans</Link>.
          </Typography>
        </div>
      ),
    },
    {
      title: `Is Hughesnet nationwide?`,
      content: (
        <Typography>
          Yes! Hughesnet is available across the contiguous US.
        </Typography>
      ),
    },
    {
      title: `Where in the US is Hughesnet available to customers?`,
      content: (
        <div>
          <Typography>
            Hughesnet is available to customers across the contiguous US,
            including rural or remote areas.
          </Typography>
        </div>
      ),
    },
    {
      title: `What factors influence Hughesnet availability in rural areas?`,
      content: (
        <div>
          <Typography>
            Hughesnet is available in many areas where cable and fiber internet
            don’t reach. It’s a great option for rural customers because as long
            as your home has a clear view of the southern sky, you can access
            Hughesnet satellite internet.
          </Typography>
          <Typography>
            Explore <Link to="/additional-services">additional services</Link>{' '}
            additional services and see{' '}
            <Link to="/installation">what to expect during installation</Link>{' '}
            to learn more about Hughesnet’s offerings.
          </Typography>
        </div>
      ),
    },
    {
      title: `What is included with Hughesnet residential installation?`,
      content: (
        <div>
          <Typography>
            Hughesnet installation is easy. When you order your Hughesnet
            internet service, a customer service representative will help you
            schedule a time for a professional technician to install your
            satellite internet. No need to provide any materials yourself—the
            technician will bring everything you need to get up and running
            after installation.
          </Typography>
          <Typography>
            Check out our installation page for more information on{' '}
            <Link to="/installation">what to expect during installation</Link>
          </Typography>
        </div>
      ),
    },
    {
      title: `What additional services does Hughesnet provide?`,
      content: (
        <div>
          <Typography>
            Hughesnet offers several additional services to improve your
            internet experience. Norton 360 Deluxe, Hughesnet Express Repair,
            Hughesnet Voice, Whole Home Wi-Fi, the Hughesnet Mobile App, and
            Priority Data Tokens can all be added to enhance your internet
            connection. Explore the{' '}
            <Link to="/additional-services">additional services</Link> to learn
            more.
          </Typography>
        </div>
      ),
    },
  ]
}
export const stateFaq = ({ State }) => [
  {
    title: `What states is Hughesnet available?`,
    content: (
      <div>
        <Typography>
          Hughesnet is available in all contiguous states in the US. As long as
          your home has a clear view of the southern sky, you can access
          Hughesnet high-speed satellite internet.
        </Typography>
      </div>
    ),
  },
  {
    title: `What cities and regions are covered by Hughesnet?`,
    content: (
      <div>
        <Typography>
          Hughesnet covers every contiguous US state. Because it’s a satellite
          internet service, Hughesnet is available even in rural areas that
          don’t have access to cable or fiber.
        </Typography>
      </div>
    ),
  },
  {
    title: `Where in the US is Hughesnet available?`,
    content: (
      <div>
        <Typography>
          Hughesnet is available in the contiguous 48 states of the US.
        </Typography>
      </div>
    ),
  },
  {
    title: `Is Hughesnet nationwide?`,
    content: (
      <div>
        <Typography>
          Yes, Hughesnet is available across the contiguous US.
        </Typography>
      </div>
    ),
  },
  {
    title: `Is Hughesnet available throughout ${State}?`,
    content: (
      <div>
        <Typography>
          Yes, {State} residents can access Hughesnet internet no matter where
          they live. As long as your home has a clear view of the southern sky,
          you can get Hughesnet high-speed satellite internet. Explore{' '}
          <Link to="/internet">internet plans</Link> and{' '}
          <Link to="/additional-services">additional services</Link> for more
          information.
        </Typography>
      </div>
    ),
  },
  {
    title: `Are there plans for expanding Hughesnet coverage in ${State}?`,
    content: (
      <div>
        <Typography>
          Since Hughesnet is a satellite service, we already provide coverage
          across {State}. However, Hughesnet is always working towards providing
          the best possible internet to our customers.
        </Typography>
      </div>
    ),
  },
]
